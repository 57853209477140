<template>
  <div>
<!--    <page-title :heading=heading :subheading="subheading" :icon=icon />-->
    <div class="content">
      <div class="row d-flex justify-content-center">
        <VueElementLoading :active="showLoading" spinner="ring" color="var(--primary)" />
        <div class="col-lg-10" style="height: 60vh" >
          <div class="main-card mb-lg-4 card" style="height: 60vh">
            <div class="card-body" style="height: 60vh">
              <h5 class="card-title">{{heading}}</h5>
              <div class="scroll-area-lg" style="height: 50vh">
                <VuePerfectScrollbar id="scroller" ref="scroller" class="scrollbar-container">
                  <p class="m-4" align="center" v-if="messages.length>0 && currentPage<totalPages" >
                    <v-btn
                      color="var(--secondary)"
                      elevation="2"
                      small
                      v-on:click="getSessionMessages()"
                  >Зареди по-стари</v-btn></p>
                  <p class="m-4" v-if="messages.length === 0">Все още няма съобщения</p>
                  <div v-else v-for="message in messages" v-bind:key="message.id">
                    <div v-if="message.sent_by_current_user==1" class="chat-box-wrapper float-right" style="margin: 0px; padding: 0px">
                        <div class="chat-box-wrapper chat-box-wrapper-right">
                          <div>
                            <div v-if="message.type==1" class="chat-box" style="border-bottom-right-radius: 0px; border-top-right-radius: 30px; background-color: #eeeeee">
                              <small>{{message.original_message.added_by_name}}: <i>"{{message.original_message.message}}"</i></small>
                            </div>
                            <div class="chat-box" style="text-align: right">
                                {{message.message}}
                            </div>
                            <small class="opacity-6">
                              {{message.added_by_name}} |
                              <font-awesome-icon icon="calendar-alt" /> {{formatDate(message.added)}}
                            </small>
                          </div>
                          <div>
                            <div class="avatar-icon-wrapper ml-1">
                              <v-avatar>
                                <img width="42" :src="imageHostname+'/storage/images/avatars/user/'+message.added_by_avatar" :alt="message.added_by_name" :hidden="!hasAvatar(message.added_by_avatar)" />
                                <span class="white--text headline" :hidden="hasAvatar(message.added_by_avatar)" >{{message.added_by_name.substring(0,1).toUpperCase()}}</span>
                              </v-avatar>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div v-else class="chat-box-wrapper" style="margin: 0px;">
                      <div>
                        <div class="avatar-icon-wrapper mr-1"  color="var(--secondary)">
                          <v-avatar v-if="message.is_anonymous==0">
                            <img width="42" :src="imageHostname+'/storage/images/avatars/user/'+message.added_by_avatar" :alt="message.added_by_name" :hidden="!hasAvatar(message.added_by_avatar)" />
                            <span class="white--text headline" :hidden="hasAvatar(message.added_by_avatar)" >{{message.added_by_name.substring(0,1).toUpperCase()}}</span>
                          </v-avatar>
                          <v-avatar v-else color="var(--secondary)">
                            <font-awesome-icon icon="user"/>
                          </v-avatar>
                          <div v-if="message.status==1 && sessionIsModerated==1" class="badge badge-bottom btn-shine badge-success badge-dot badge-dot-lg"></div>
                          <div v-else-if="message.status==-1 && sessionIsModerated==1" class="badge badge-bottom btn-shine badge-danger badge-dot badge-dot-lg"></div>
                          <div v-else-if="sessionIsModerated==1" class="badge badge-bottom btn-shine badge-dot badge-secondary badge-pulse badge-dot-lg"></div>
                          <b-dropdown v-if="sessionIsModerated==1" toggle-class="btn-icon btn-icon-only" variant="link" right no-caret>
                            <span slot="button-content" style="color: black">
                              <i class="pe-7s-menu btn-icon-wrapper"></i>
                            </span>
                            <ul class="nav flex-column">
                              <li class="nav-item">
                                <a href="javascript:void(0);" v-on:click="setMessageStatus(message,1)" class="nav-link" style="color: var(--primary)"
                                >
                                  <font-awesome-icon icon="check" class="mr-1"/>
                                  Направи публично
                                </a>
                              </li>
                              <li class="nav-item">
                                <a href="javascript:void(0);" v-on:click="setMessageStatus(message,-1)" class="nav-link" style="color: var(--primary)"
                                >
                                  <font-awesome-icon icon="ban" class="mr-1"/>
                                  Модерирай
                                </a>
                              </li>
                              <li class="nav-item" v-if="message.is_answered==0">
                                <a @click="openResponse(message)" class="nav-link" style="color: var(--primary)">
                                  <font-awesome-icon icon="reply" class="mr-1"/>
                                  Отговор
                                </a>
                              </li>
                            </ul>
                          </b-dropdown>
                        </div>

                      </div>
                      <div>
                        <div v-if="message.type==1" class="chat-box" style="border-bottom-left-radius: 0px; border-top-left-radius: 30px; background-color: #eeeeee">
                          <small>{{message.original_message.added_by_name}}: <i>"{{message.original_message.message}}"</i></small>
                        </div>
                        <div class="chat-box">
                          {{message.message}}</div>

                        <small v-if="message.is_anonymous==0" class="opacity-6">
                          {{message.added_by_name}} |
                          <font-awesome-icon icon="calendar-alt" /> {{formatDate(message.added)}}
                        </small>
                        <small v-else class="opacity-6">
                          Анонимен |
                          <font-awesome-icon icon="calendar-alt" /> {{formatDate(message.added)}}
                        </small>
                      </div>
                    </div>
                  </div>
                </VuePerfectScrollbar>
              </div>
            </div>
            <div class="d-block text-right card-footer">
              <div class="row">
                <font-awesome-icon icon="clock" style="height: 35px; margin: 10px"/>
                <b-form-select class="col-sm-1"   style="height: 35px; margin: 10px" v-on:change="setNewInterval" v-model="selectedInterval" :options="intervals"></b-form-select>

                <v-text-field class="col-sm-8" type="text" placeholder="Вашето съобщение..." v-model="freeInputMessage"/>
                  <button class="btn btn-light col-sm-2" style="height: 35px; margin: 10px" v-on:click="sendMessage(0)">
                  Изпрати
                  <font-awesome-icon icon="paper-plane" title="Изпрати" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Respond to message-->
      <b-modal id="modal-respond" title="Отговор на съобщение"  no-body hide-footer>
        <VueElementLoading :active="showLoadingModalResponse" spinner="ring" color="var(--primary)" />
        <small><b>{{respondingToMessageAuthor}}</b></small>
        <h6><i>"{{respondingToMessage}}</i>"</h6>
        <v-text-field v-model="responseInputMessage" label="Отговор" required></v-text-field>
        <div class="text-center">
          <b-button variant="success" @click="sendResponse()">
            Изпрати
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import VueElementLoading from "vue-element-loading";
import swal from "sweetalert";
import QnASessionsService from "@/services/qna_sessions.service"
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import PageTitle from "@/Layout/Components/PageTitle";
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faBan,
  faReply,
  faPaperPlane,
    faUser,
  faClock
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
library.add(
    faCheck,
    faBan,
    faReply,
    faPaperPlane,
    faUser,
    faClock
);


export default {
  name: 'QnASessionMessages',
  components: {
    PageTitle,
    VuePerfectScrollbar,
    VueElementLoading,
    'font-awesome-icon': FontAwesomeIcon,
  },
  props:{
    sessionId: {
      type: String,
      default: "0",
    }
  },
  data() {
    return {
      imageHostname: Vue.prototype.$hostnameImages,
      heading: "",
      subheading: '',
      icon: 'pe-7s-comment icon-gradient bg-happy-itmeo',
      showLoading: false,
      showLoadingModalResponse: false,
      session:{},
      messages:[],
      currentPage:0,
      totalPages:0,
      freeInputMessage:"",
      responseInputMessage:"",
      respondingTo:{},
      respondingToMessageAuthor:"",
      respondingToMessage:"",
      lastMessageId:-1,
      sessionIsModerated: 0,
      interval:{},
      selectedInterval: 15,
      intervals: [
        {text: "5s", value: 5},
        {text: "15s", value: 15},
        {text: "30s", value: 30},
        {text: "1m", value: 60},
        {text: "5m", value: 300},
        {text: "15m", value: 900},
        {text: "30m", value: 1800},
        {text: "1h", value: 3600},
      ]
    };
  },
  computed: {

  },
  created() {
    this.getSessionMessages();
  },
  mounted () {
    this.interval = window.setInterval(() => {
      this.getSessionLatestMessages()
    }, this.selectedInterval*1000)

    this.heading = this.sessionTitle
  },
  methods: {
    getSessionMessages()
    {
      this.showLoading = true

      if(this.currentPage>0)
      {
        this.currentPage += 1
      }

      QnASessionsService.getSessionMessages(this.sessionId,this.currentPage).then((response) =>
      {
        response

        this.session = response.data.response.session;
        this.heading = this.session.title;
        this.subheading = this.strip(this.session.subtitle)
        this.sessionIsModerated = this.session.is_moderated

        this.currentPage = response.data.response.results.current_page
        this.totalPages = response.data.response.results.last_page

        var _messages = response.data.response.results.data

        for (const message of _messages) {
          this.messages.push(message)
        }

        this.messages = this.messages.sort(function(a, b) {
        var c = new Date(a.added).getTime();
        var d = new Date(b.added).getTime();
        return c-d;
      });

        this.lastMessageId = this.messages[this.messages.length-1].id
        if(this.currentPage==1)
        {
          this.scrollDown()
        }
      },
          error => {
            this.showLoading = false;
            this.handleError(error);
          })

      this.showLoading = false
    },
    getSessionLatestMessages()
    {
      this.showLoading = true
      QnASessionsService.getSessionLatestMessages(this.sessionId,this.lastMessageId).then((response) =>
      {
        response

        var _messages = response.data.response.results.data.sort(function(a, b) {
          var c = new Date(a.added).getTime();
          var d = new Date(b.added).getTime();
          return c-d;
        });

        for (const message of _messages) {
          this.messages.push(message)
        }

        this.lastMessageId = this.messages[this.messages.length-1].id
        if(_messages.length()>0)
        {
          this.scrollDown()
        }
      },
          error => {
            this.showLoading = false;
            this.handleError(error);
          })

      this.showLoading = false
    },
    sendMessage(messageType)
    {
      this.showLoading = true

      QnASessionsService.sendMessage(this.sessionId, this.freeInputMessage, messageType).then((response) =>
          {
            response
            this.freeInputMessage = ""
            this.getSessionLatestMessages()
          },
          error => {
            this.showLoading = false;
            this.handleError(error);
          })

      this.showLoading = false
    },
    openResponse(message)
    {
      this.respondingToMessageAuthor = message.added_by_name
      this.respondingToMessage = message.message;
      this.respondingTo = message;
      this.$root.$emit('bv::toggle::modal', 'modal-respond', '#btnToggle');
    },
    sendResponse()
    {
      this.showLoadingModalResponse = true

      QnASessionsService.sendMessageResponse(this.sessionId, this.respondingTo.id, this.responseInputMessage).then((response) =>
          {
            response
            this.responseInputMessage = ""
            this.$root.$emit('bv::toggle::modal', 'modal-respond', '#btnToggle')

            this.setMessageStatus(this.respondingTo,1)
            this.getSessionLatestMessages()
          },
          error => {
            this.showLoadingModalResponse = false;
            this.handleError(error);
          })

      this.showLoadingModalResponse = false
    },
    setMessageStatus(message,status)
    {
      this.showLoading = true

      QnASessionsService.setMessageStatus(message.id,status).then((response) =>
          {
            response
            message.status = status
          },
          error => {
            this.showLoading = false;
            this.handleError(error);
          })

      this.showLoading = false
    },
    setNewInterval()
    {
      clearInterval(this.interval);

      this.interval = window.setInterval(() => {
        this.getSessionLatestMessages()
      }, this.selectedInterval*1000)
    },
    scrollDown() {
      let objDiv = document.getElementById('scroller');
      this.$nextTick(() => {
        objDiv.scrollTop = objDiv.scrollHeight;
      })
    },
    handleError(error)
    {
      let errorMsg =
          error.message || (error.response && error.response.data && error.response.data.message) ||
          error.toString();
      if(error.response.data.error.message)
      {
        swal("Грешка", error.response.data.error.message, "error");
      }
      if (error.response && error.response.status === 500)
      {
        console.log(errorMsg)
      }
    },
    formatDate(date)
    {
      return moment(date).utcOffset('+0000').format('DD.MM.yy HH:mm')
    },
    hasAvatar(avatar)
    {
      if(!avatar)
      {
        return false
      }
      else
      {
        if(avatar.endsWith(".webp"))
        {
          return true
        }
        else
        {
          return false;
        }
      }
    },
    strip(html)
    {
      let doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || "";
    }
  }
};
</script>